import { DataTable } from "primereact/datatable";
import AppLayout from "../components/layout/AppLayout";
import { Column } from "primereact/column";
import { useCallback, useMemo, useState } from "react";
import { InputText } from "primereact/inputtext";
import useZascaTacticalHook from "../hooks/useZascaTacticalHook";
import { DebounceInput } from "react-debounce-input";
 import { Checkbox } from 'primereact/checkbox';
 // import { Calendar } from "primereact/calendar";
 import { Dropdown } from 'primereact/dropdown';
import InfoProductoButton from "../components/tactical/InfoProductoButton";
import PreciosProveedorIcono from "../components/tactical/PreciosProveedorIcono";

const ZascaTactical = () => {
	const [prov, setProv] = useState();
	const { productosAmazon, productosProv, isLoadingProductosAmazon, isLoadingProductosProv,
		proveedores} = useZascaTacticalHook({prov, pideTactical:true});

	const [filters, setFilters] = useState({
		rank1: false,
		rank2: true,
		rank3: false,
		precioAmazon: false,
		precioFba: false,
		comision: true,
		beneficio: true
	});

	const productosValidos = useMemo(
		() => productosProv?.filter((p) => productosAmazon?.find((pa) => pa.ean === p.eanCorregido && 
		(pa.precioBestAmazon || pa.precioBestFBA || pa.precioBestFBM	))),
		[productosAmazon, productosProv]
	);

	const calculaMejorPrecio = (pa) => {
		if (!pa) return 0;
		if (pa.precioBestAmazon && pa.precioBestFBA && pa.precioBestFBM) {
			if (pa.precioBestAmazon < pa.precioBestFBA && pa.precioBestAmazon < pa.precioBestFBM) {
				return pa.precioBestAmazon;
			}
			if (pa.precioBestFBA < pa.precioBestAmazon && pa.precioBestFBA < pa.precioBestFBM) {
				return pa.precioBestFBA;
			}
			if (pa.precioBestFBM < pa.precioBestAmazon && pa.precioBestFBM < pa.precioBestFBA) {
				return pa.precioBestFBM;
			}
		}
		if (pa.precioBestAmazon && pa.precioBestFBA) {
			if (pa.precioBestAmazon < pa.precioBestFBA) {
				return pa.precioBestAmazon;
			}
			return pa.precioBestFBA;
		}
		if (pa.precioBestAmazon && pa.precioBestFBM) {
			if (pa.precioBestAmazon < pa.precioBestFBM) {
				return pa.precioBestAmazon;
			}
			return pa.precioBestFBM;
		}
		if (pa.precioBestFBA && pa.precioBestFBM) {
			if (pa.precioBestFBA < pa.precioBestFBM) {
				return pa.precioBestFBA;
			}
			return pa.precioBestFBM;
		}
		if (pa.precioBestAmazon) return pa.precioBestAmazon;
		if (pa.precioBestFBA) return pa.precioBestFBA;
		if (pa.precioBestFBM) return pa.precioBestFBM;
		return 0;
	};

	const data = useMemo(() => {
		let dat = productosValidos?.map((p) => {
			const pa = productosAmazon.find((pA) => p.eanCorregido === pA.ean);
			const mejorPrecio =calculaMejorPrecio(pa);
			const totalComisionFba = ((pa?.feesVenta / 100) * mejorPrecio + pa?.feesFBA)?.toFixed(2);
			const beneficio = mejorPrecio - totalComisionFba - p.pvd * 1.21;
			const roi = (100 * beneficio) / (p.pvd * 1.21);
			const pvd = p.pvdOferta ?? p.pvd;
			return {
				...pa,
				asin: pa?.asin,
				ean: p.ean,
				nombre: p.nombre,
				proveedor: proveedores?.find((p) => p.id === p.idProveedor)?.nombre,
				nombreAmazon: pa?.titulo,
				marca: pa?.marca,
				precioAmazon: pa?.precioBestAmazon,
				precioFba: pa?.precioBestFBA,
				precioFbM: pa?.precioBestFBM,
				mejorPrecio,
				precioBuyBox: pa?.precioBuyBox,
				amazonSell: pa?.amazonSell,
				comision: totalComisionFba,
				beneficio: beneficio?.toFixed(2),
				beneficioVal: beneficio,
				roiF: roi,
				roi: roi.toFixed(2),
				pvd: (pvd * 1.21)?.toFixed(2),
				stock: p.stock,
				rank1: pa?.categoriaRanking1,
				rank2: pa?.categoriaRanking2,
				rank3: pa?.categoriaRanking3,
				fechaAct: new Date(p?.fechaAct)?.toLocaleDateString(),
				lastPriceData: new Date(pa?.fechaPrices),
				shippingTimeFba: pa?.shippingTime,
				shippingAvailability: pa?.shippingAvailability,
			};
		});
		return dat;
	}, [productosAmazon, productosValidos, proveedores]);

	const datFiltered = useMemo(() => {
		let dat = data;
		if (filters.ean) {
			dat = dat?.filter((d) => d.ean?.toLowerCase().includes(filters.ean.toLowerCase()));
		}
		if (filters.nombre) {
			dat = dat?.filter((d) => d.nombre?.toLowerCase().includes(filters.nombre.toLowerCase()));
		}
		if (filters.nombreAmazon) {
			dat = dat?.filter((d) => d.nombreAmazon?.toLowerCase().includes(filters.nombreAmazon.toLowerCase()));
		}
		if (filters.rank1) {
			dat = dat?.filter((d) => d.rank1);
		}
		if (filters.rank2) {
			dat = dat?.filter((d) => d.rank2);
		}
		if (filters.rank3) {
			dat = dat?.filter((d) => d.rank3);
		}
		if (filters.precioAmazon) {
			dat = dat?.filter((d) => d.precioAmazon);
		}
		if (filters.precioFba) {
			dat = dat?.filter((d) => d.precioFba);
		}
		if (filters.lastPriceData) {
			dat = dat?.filter((d) => d.lastPriceData >= filters.lastPriceData);
		}
		if (filters.comision) {
			dat = dat?.filter((d) => d.comision>0);
		}
		if (filters.shippingTimeFba) {
			dat = dat?.filter((d) => d.shippingTimeFba === filters.shippingTimeFba);
		}
		if (filters.beneficio) {
			dat = dat?.filter((d) => d.beneficioVal > 0);
		}
		return dat;
	}, [data, filters]);

	const renderLink = useCallback((row, col) => {
		const value = row[col.field];
		return (
			<a href={`http://www.amazon.es/dp/${row.asin}`} target='_blank' rel='noreferrer'>
				{value}
			</a>
		);
	}, []);

	const renderWuro = useCallback((row, col) => {
		const value = row[col.field];
		return <span>{value} €</span>;
	}, []);

	const renderFecha = useCallback((row, col) => {
		const value = row[col.field];
		return <span>{value?.toLocaleDateString()}</span>;
	}, []);

	const renderPorcentaje = useCallback((row, col) => {
		const value = row[col.field];
		let color = '';
		if (value > 5) color = 'text-green-400';
		if (value > 10) color = 'text-green-600';
		if (value > 20) color = 'text-purple-300';
		if (value > 40) color = 'text-yellow-600';
		if (value > 60) color = 'text-orange-600';
		if (value > 80) color = 'text-red-600';
		if (value > 100) color = 'text-red-800';
		if (value > 400) color = 'text-red-900';

		return <span className={`${color} text-sm`}>{value}</span>;
	}, []);

	const renderBeneficio = useCallback((row, col) => {
		const value = row[col.field];
		let color = '';
		if (value <0) color = 'text-gray-600';
		if (value > 0) color = 'text-green-600';
		if (value > 10) color = 'text-yellow-600';
		if (value > 20) color = 'text-orange-600';
		if (value > 30) color = 'text-red-600';
		if (value > 40) color = 'text-red-800';
		if (value > 50) color = 'text-red-900';
		return <span className={`${color} text-sm`}>{value} €</span>;
	},[]);

	const renderBool = useCallback((row, col) => {
		const value = row[col.field];
		const color = value ? 'text-red-600' : 'text-green-600';
		return <span className={`${color} text-sm font-bold`}>{value ? 'Si' : 'No'}</span>;
	}, []);

	const renderComision = useCallback((row, col) => {
		const value = row[col.field];
		let color = value > 0 ? 'text-green-600' : 'text-red-600';
		return <span className={`${color} text-sm`}>{value} €</span>;
	}, []);

	const renderIcons = useCallback(rowData => {
		const productoProv = productosProv.find(p => p.eanCorregido === rowData.ean);
		const productoAmazon = productosAmazon.find(p => p.ean === rowData.ean);
		return <InfoProductoButton productoProv={productoProv}  productoAmazon={productoAmazon} />;
	},[productosAmazon, productosProv]);

	const filterText = useCallback(
		(options) => {
			return (
				<span className='p-input-icon-left'>
					<DebounceInput
						element={InputText}
						debounceTimeout={500}
						className='p-1 text-xs'
						value={filters[options.field]}
						onChange={(e) => setFilters({ ...filters, [options.field]: e.target.value })}
					/>
				</span>
			);
		},
		[filters]
	);

	const filterBool = useCallback(
		(options) => (
			<Checkbox
				checked={filters[options.field]}
				onChange={(e) => setFilters({ ...filters, [options.field]: e.checked })}
			/>
		),
		[filters]
	);

	

	const renderPvd = useCallback((row, col) => {
		const value = row[col.field];
		return <span>{value} € <PreciosProveedorIcono ean={row.ean} /> </span>;
	}, []);


	return (
		<AppLayout>
			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-12 p-3'>
					<Dropdown
						value={prov}
						options={proveedores?.filter((p) => p.habilitado)?.map((p) => ({ label: p.nombre, value: p.id }))}
						onChange={(e) => setProv(e.value)}
						placeholder='Select a Value'
						filter
						showClear
					/>
					{isLoadingProductosProv && <h4>Cargando productos proveedor activos </h4>}
					{isLoadingProductosAmazon && <h4>Cargando productos AMAZON activos </h4>}
					<DataTable
						loading={isLoadingProductosProv || isLoadingProductosAmazon}
						value={datFiltered}
						stripedRows
						size='small'
						filterDisplay='row'
						globalFilterFields={['codigoP', 'nombre', 'proveedor', 'ean']}
						paginator
						paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
						currentPageReportTemplate='Showing {first} to {last} of {totalRecords}'
						rows={15}
						style={{ fontSize: '10px' }}
						rowsPerPageOptions={[15, 20, 50]}
						rowClassName={(rowData) => {
							if (!rowData.comision) return 'bg-red-200';
							return '';
						}}
						scrollable
						scrollHeight='650px'
						sortField='rank2'
						sortOrder={1}
					>
						<Column
							header='Ean'
							field='ean'
							style={{ width: '90px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
							filterMenuStyle={{ width: '14rem' }}
						/>
						<Column
							header='Nombre'
							field='nombre'
							style={{ width: '300px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column
							header='Nombre Am'
							filter
							filterElement={filterText}
							showFilterMenu={false}
							body={renderLink}
							field='nombreAmazon'
							style={{ width: '300px' }}
						></Column>
						<Column
							header='Pvd'
							field='pvd'
							style={{ width: '150px', fontSize: '11px', fontWeight: 'bold' }}
							sortable
							body={renderPvd}
						></Column>
						<Column header='Stock' field='stock' sortable></Column>
						<Column
							header='Prec. amazon '
							field='precioAmazon'
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							style={{ width: '100px' }}
							sortable
							body={renderWuro}
						></Column>
						<Column
							header='Prec. FBA'
							field='precioFba'
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							style={{ width: '100px' }}
							sortable
							body={renderWuro}
						></Column>
						<Column
							header='Prec. FBM'
							field='precioFbM'
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							style={{ width: '100px' }}
							sortable
							body={renderWuro}
						></Column>
						<Column
							header='Best'
							field='mejorPrecio'
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							style={{ width: '100px' }}
							sortable
							body={renderWuro}
						></Column>
						<Column
							header='Comision'
							field='comision'
							style={{ width: '150px' }}
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							sortable
							body={renderComision}
						></Column>
						<Column
							header='Beneficio'
							field='beneficio'
							filter
							filterElement={filterBool}
							showFilterMenu={false}
							sortable
							style={{ fontSize: '12px', fontWeight: 'bolder' }}
							body={renderBeneficio}
						></Column>
						<Column
							header='ROI'
							field='roi'
							style={{ fontSize: '11px', fontWeight: 'bolder' }}
							sortable
							body={renderPorcentaje}
						></Column>
						<Column
							header='Rank1'
							field='rank1'
							sortable
							filter
							filterElement={filterBool}
							showFilterMenu={false}
						></Column>
						<Column
							header='Rank2'
							field='rank2'
							sortable
							filter
							filterElement={filterBool}
							showFilterMenu={false}
						></Column>
						<Column
							header='Rank3'
							field='rank3'
							sortable
							filter
							filterElement={filterBool}
							showFilterMenu={false}
						></Column>
						<Column
							header='Sellers FBA'
							field='numSellersFBA'
							sortable
							filter
							filterElement={filterBool}
							showFilterMenu={false}
						></Column>
						<Column
							header='Sellers FBM'
							field='numSellersFBM'
							sortable
							filter
							filterElement={filterBool}
							showFilterMenu={false}
						></Column>
						<Column header='Amazon?' field='amazonSell' sortable body={renderBool}></Column>
						<Column
							header='Fecha price'
							field='lastPriceData'
							// filter
							// filterElement={filterDate}
							showFilterMenu={false}
							sortable
							body={renderFecha}
						></Column>
						{/* <Column
							header='Shipping t'
							field='shippingTimeFba'
							filter
							filterElement={filterValues}
							showFilterMenu={false}
						></Column>
						<Column
							header='Shipping av'
							field='shippingAvailability'
							filter
							filterElement={filterValues}
							showFilterMenu={false}
						></Column> */}
						<Column header='' body={renderIcons}></Column>
					</DataTable>
				</div>
			</div>
		</AppLayout>
	);
};

export default ZascaTactical;