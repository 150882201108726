import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import PropTypes from "prop-types";
import useZascaEanHook from "../../hooks/useZascaEanHook";
import { useCallback, useMemo } from "react";
import useZascaTacticalHook from "../../hooks/useZascaTacticalHook";

const TablaProveedoresEan = ({ean, mini})=>{

    const {  productosProv } = useZascaEanHook(ean);
    const {proveedores}=useZascaTacticalHook();

    const datos = useMemo(
			() =>
				productosProv?.map(
					(p) => ({
						...p,
						pvd: p.pvdOferta ? p.pvdOferta : p.pvd,
						proveedorNombre: proveedores?.find((pr) => pr.id+'' === p.idProveedor)?.nombre,
						esOferta: p.pvdOferta > 0,
					})
					// eslint-disable-next-line react-hooks/exhaustive-deps
				),
			[productosProv, proveedores]
		);

    const renderFecha = useCallback(row=><span>
        {(new Date(row?.fechaAct)?.toLocaleDateString())}
    </span>,[]);

    const renderStock = useCallback(row=><span>
        {row.stock >0? <span className='text-red-400 text-sm font-bold'>{row.stock}</span> : row.stock  }
    </span>,[]);

    const renderPvd = useCallback(row=><span className='font-bold text-sm'>
        {row.esOferta?<i className='pi pi-star'/>:''} {row.pvd.toFixed(2)} €
    </span>,[]);

    const masBarato = useMemo(()=>{
       let masBarato = productosProv?.find(p=>p.stock>0)?.pvd;
         productosProv?.forEach(p=>{
              if(p.stock>0 && p.pvd < masBarato){
                masBarato = p.pvd;
              }
         });
         return masBarato;
    },[productosProv]);
    const rowClass = useCallback(row=>{
        if(row.pvd === masBarato){
            return 'bg-green-200';
        }
        return '';
    }
    ,[masBarato]);

    return (
			<div className='col-12'>
				<DataTable
					// loading={isLoadingProductos}
					value={datos}
					stripedRows
					size='small'
					className='p-1 text-xs'
					rowClassName={rowClass}
				>
					{!mini && <Column header='ID' field='id' />}
					{!mini && <Column header='Ean' field='ean' style={{ width: '90px' }} />}

					<Column header='Proveedor' field='proveedorNombre'  />
					{!mini && <Column header='Id interno' field='idInternoProveedor' />}
					{!mini && <Column header='Nombre' field='nombre' style={{ width: '300px' }} />}
					{!mini && <Column header='Categoria' field='categoria' />}
					{/* <Column header='Desc corta' field='descripcionCorta' />
                                <Column header='Desc' field='descripcion' /> */}
					<Column header='Stock' field='stock' sortable body={renderStock} />
					<Column header='Pvd' field='pvd' sortable body={renderPvd} />
					{!mini && <Column header='Peso' field='peso' />}
					<Column header='Fecha' field='fechaAct' body={renderFecha} sortable />
				</DataTable>
			</div>
		);

};

export default TablaProveedoresEan;

TablaProveedoresEan.propTypes = {
    ean: PropTypes.string.isRequired,
    mini: PropTypes.bool
};