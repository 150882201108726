import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import InfoProducto from './InfoProducto';

const InfoProductoButton = ({ productoProv, productoAmazon }) => {
    const [open, setOpen] = useState(false);
    
    const handleClick = useCallback(() => {
        setOpen(!open);
    },[open]);
    
    return (
			<>
				<Button icon='pi pi-question' className='p-0' severity='info' rounded onClick={handleClick} />

				{open && <InfoProducto productoProv={productoProv} productoAmazon={productoAmazon} onClose={handleClick}/>}
			</>
		);
  
};
export default InfoProductoButton;

InfoProductoButton.propTypes = {
	productoProv: PropTypes.object.isRequired,
    productoAmazon: PropTypes.object.isRequired
};
