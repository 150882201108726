
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import TablaProveedoresEan from '../infoEan/TablaProveedoresEan';
import PropTypes from 'prop-types';

const PreciosProveedorIcono = ({ ean }) => {
    const id = `tooltip-${ean}`
	return (
		<>
			<Button
				icon='pi pi-shopping-cart'
				className={id}
				tooltipOptions={{ position: 'top' }}
                rounded
			/>
			<Tooltip
				target={`.${id}`}
				>
                    <TablaProveedoresEan ean={ean} mini />
                </Tooltip>
		</>
	);
};

PreciosProveedorIcono.propTypes = {
    ean: PropTypes.string.isRequired
};  

export default PreciosProveedorIcono;
