import { useCallback, useMemo, useState } from 'react';
import AppLayout from '../components/layout/AppLayout';
import useZascaTacticalHook from '../hooks/useZascaTacticalHook';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { DebounceInput } from 'react-debounce-input';
import { InputText } from 'primereact/inputtext';

const NovedadesPage = () => {
	const [prov, setProv] = useState();
	const [fecha, setFecha] = useState(new Date());
	const [filters, setFilters] = useState({});
	const { proveedores, novedadesProveedor, isLoadingNovedadesProveedor } = useZascaTacticalHook({ prov, fecha });

	const renderImagen = useCallback((rowData) => {
        const imagenes = rowData.imagenes ? rowData.imagenes.split(',') : [];
        const imagen = imagenes.length > 0 ? imagenes[0] : '';
        return <Image src={imagen} alt='imagen'  height="80px" preview/>;
    },[]);

	const datosFiltered = useMemo(()=>{
		let datos = novedadesProveedor??[];
		if (filters?.ean){
			datos = datos.filter((item) => item.ean.toLowerCase().includes(filters?.ean.toLowerCase()));
		}
		if (filters?.eanCorregido){
			datos = datos.filter((item) => item.eanCorregido.toLowerCase().includes(filters?.eanCorregido.toLowerCase()));
		}
		if (filters?.nombre){
			datos = datos.filter((item) => item.nombre.toLowerCase().includes(filters?.nombre.toLowerCase()));
		}
		if (filters?.categoria){
			datos = datos.filter((item) => item.categoria.toLowerCase().includes(filters?.categoria.toLowerCase()));
		}
		if (filters?.marca){
			datos = datos.filter((item) => item.marca.toLowerCase().includes(filters?.marca.toLowerCase()));
		}
		return datos;
	},[filters, novedadesProveedor]);

		const filterText = useCallback(
			(options) => {
				return (
					<span className='p-input-icon-left'>
						<DebounceInput
							element={InputText}
							debounceTimeout={500}
							className='p-1 text-xs'
							value={filters[options.field]}
							onChange={(e) => setFilters({ ...filters, [options.field]: e.target.value })}
						/>
					</span>
				);
			},
			[filters]
		);

	return (
		<AppLayout>
			<div className='grid align-items-center justify-content-center  m-0 p-0'>
				<div className='col-12 p-3'>
					<Dropdown
						value={prov}
						options={proveedores?.filter((p) => p.habilitado)?.map((p) => ({ label: p.nombre, value: p.id }))}
						onChange={(e) => setProv(e.value)}
						placeholder='Select a Value'
						filter
						showClear
					/>
					<Calendar value={fecha} onChange={(e) => setFecha(e.value)} showIcon />
				</div>
				<div className='col-12 p-3'>
					<DataTable
						filterDisplay='row'
						value={datosFiltered}
						loading={isLoadingNovedadesProveedor}
						stripedRows
						size='small'
						scrollable
						scrollHeight='650px'
						className='text-sm'
					>
						<Column header='Imagen ' field='imagenes' style={{ width: '90px' }} body={renderImagen} />
						<Column
							header='Ean '
							field='ean'
							style={{ width: '90px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column
							header='Ean C'
							field='eanCorregido'
							style={{ width: '90px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column
							header='Nombre'
							field='nombre'
							style={{ width: '300px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column
							header='Categoria'
							field='categoria'
							style={{ width: '90px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column
							header='Marca'
							field='marca'
							style={{ width: '90px' }}
							filter
							filterElement={filterText}
							showFilterMenu={false}
						/>
						<Column header='PVD' field='pvd' style={{ width: '90px' }} />
						<Column header='stock' field='stock' style={{ width: '90px' }} />
					</DataTable>
				</div>
			</div>
		</AppLayout>
	);
};

export default NovedadesPage;
