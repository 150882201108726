import {  useState } from "react";
import AppLayout from "../components/layout/AppLayout";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import DatosProducto from "../components/tactical/DatosProducto";
import TablaProveedoresEan from "../components/infoEan/TablaProveedoresEan";
import useZascaEanHook from "../hooks/useZascaEanHook";

const ConsultaEanPage = ()=>{
    const [ean, setEan] = useState('');
    const [eanBuscar, setEanBuscar]=useState(null);
	const { productoAmazon, productoZascalo } = useZascaEanHook(eanBuscar);
    
console.log(productoAmazon);

    return (
			<AppLayout>
				<h2 className='p-0 m-0'>
					Datos EAN:
					<InputText value={ean} onChange={(e) => setEan(e.target.value)} />
					<Button label='Buscar' onClick={() => setEanBuscar(ean)} />
				</h2>
				<h3> Producto Zascalo</h3>
				<div className='grid'>
					{eanBuscar && <TablaProveedoresEan ean={eanBuscar} />}
					{eanBuscar           && <DatosProducto productoProv={productoZascalo}
						productoAmazon={productoAmazon?.length > 0 ? productoAmazon[0]:null} />}
				</div>
			</AppLayout>
		);
};

export default ConsultaEanPage;