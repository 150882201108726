import PropTypes from 'prop-types';
//import { toJavaDate } from 'src/services/previsionesHelpers';
import { ComposedChart, Line,  XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const GraficoLineas = ({ value, attribute1, attribute2, attribute3 }) => {
    
    return (
        // <div style={{ width: '900px', height: '200px'}}>
            <ComposedChart
                width={600}
                height={200}
                data={value}
                // margin={{
                // 	top: 20,
                // 	right: 20,
                // 	bottom: 20,
                // 	left: 20,
                // }}
            >
                <CartesianGrid stroke='#f5f5f5' />
                <XAxis dataKey='fecha' scale='auto' tick={{ fontSize: 9 }} />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type='monotone' dataKey={attribute1} stroke='#F5AEC6' strokeWidth={3} />
                <Line type='monotone' dataKey={attribute2} stroke='#9FE0C6' strokeWidth={3} />
                <Line type='monotone' dataKey={attribute3} stroke='#59D5E0' strokeWidth={3} />
            </ComposedChart>
        // </div>
    );
};
GraficoLineas.propTypes = {
    value: PropTypes.array,
    attribute1: PropTypes.string,
    attribute2: PropTypes.string,
    attribute3: PropTypes.string
};
export default GraficoLineas;
