import { Menubar } from "primereact/menubar";
import PropTypes from 'prop-types';
import UsuarioComponent from "../../auth/UsuarioComponent";

import login from '../../assets/login.png';
import { useCallback } from "react";
import { useNavigate } from "react-router";

const AppLayout = ({children})=>{
	const navigate = useNavigate();

    const goTo = useCallback(
			(url) => {
				navigate(url);
			},
			[navigate]
		);


	const items = [
		{
			label: 'Inicio',
			icon: 'pi pi-home',
			command: () => {
				goTo('/');
			},
		},
		{
			label: 'Productos',
			icon: 'pi pi-star',
			command: () => {
				goTo('/productos');
			},
		},
		{
			label: `Almacenes `,
			icon: 'pi pi-box',
			//     command: ()=>{ goTo('/recetas/DESAYUNO')}
			items: [
				{
					label: `Zascalo `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/almacen/1');
					},
				},
				{
					label: `Outlet `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/almacen/2');
					},
				},
			],
		},
		{
			label: `Movimientos `,
			icon: 'pi pi-box',
			//     command: ()=>{ goTo('/recetas/DESAYUNO')}
			items: [
				{
					label: `Zascalo `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/movimientos/1');
					},
				},
				{
					label: `Outlet `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/movimientos/2');
					},
				},
			],
		},
		{
			label: `Informes `,
			icon: 'pi pi-box',
			//     command: ()=>{ goTo('/recetas/DESAYUNO')}
			items: [
				{
					label: `Zascalo `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/movimientos/1');
					},
				},
				{
					label: `Outlet `,
					icon: 'pi pi-clock',
					command: () => {
						goTo('/movimientos/2');
					},
				},
			],
		},
		{
			label: `Tactical `,
			icon: 'pi pi-euro',
			command: () => {
				goTo('/zascaTactical');
			},
		},
		{
			label: 'Ean',
			icon: 'pi pi-search',
			command: () => {
				goTo('/buscaEan');
			},
		},
		{
			label: 'Novedades',
			icon: 'pi pi-exclamation-circle',
			command: () => {
				goTo('/novedades');
			},
		},
		{
			label: `Inventarios `,
			icon: 'pi pi-crown',
			command: () => {
				goTo('/inventarios');
			},
		},
	];

    return (
			<div className='surface-0 relative '>
				<Menubar
					start={
						<img	
							alt='logo'
							src={login}
							height='50'
							style={{
								marginRight: '5px',
								borderRadius: '8px',
								padding: '3px',
								marginTop: '-5px',
								boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
							}}
						/>
					}
					end={<UsuarioComponent />}
					model={items}
					className='surface-300  p-1'
				/>

				<div
					className='surface-0 flex-column flex-auto'
					style={{ maxHeight: 'calc(100vh - 100px)', overflowY: 'scroll', overflowX: 'hidden' }}
				>
					{children}
				</div>
			</div>
		);
}

AppLayout.propTypes={
    children: PropTypes.any
}

export default AppLayout ;