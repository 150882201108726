
import { Dialog } from 'primereact/dialog';
import PropTypes from 'prop-types'
import { TabView, TabPanel } from 'primereact/tabview';
import DatosProducto from './DatosProducto';
import DatosHistorico from './DatosHistorico';
import TablaProveedoresEan from '../infoEan/TablaProveedoresEan';
const InfoProducto = ({ productoProv, productoAmazon, onClose }) => {
	return (
		<Dialog
			header={productoProv.nombre}
			visible={true}
			maximized
			style={{ height: '90vh', maxHeight: '800px' }}
			onHide={onClose}
		>
			<TabView>
				<TabPanel header='Datos Histórico'>
					<DatosHistorico asin={productoAmazon.asin} />
				</TabPanel>
				<TabPanel header='Proveedores'>
					<TablaProveedoresEan ean={productoProv.eanCorregido} />
				</TabPanel>
				<TabPanel header='Datos producto'>
					<DatosProducto productoProv={productoProv} productoAmazon={productoAmazon} />
				</TabPanel>
			</TabView>
		</Dialog>
	);
};

export default InfoProducto;

InfoProducto.propTypes = {
	productoProv: PropTypes.object.isRequired,
	productoAmazon: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
};