import { useQuery } from "@tanstack/react-query";
import { getBigDataService,  getNovedadesProveedorService,  getProductosAmazonService, getProductosProveedorService, getProveedoresService } from "../services/zascaSyncService";
import { toJavaDate } from "../services/commonHelper";
import { useMemo } from "react";

const useZascaTacticalHook = ({ prov, asin, pideTactical, fecha } = {}) => {
	const { data: productosAmazon, isLoading: isLoadingProductosAmazon } = useQuery({
		queryKey: ['productosAmazon'],
		queryFn: async () => await getProductosAmazonService(),
		enabled: pideTactical,
	});

	const { data: productosProv, isLoading: isLoadingProductosProv } = useQuery({
		queryKey: ['productosProv', prov],
		queryFn: async () => await getProductosProveedorService(prov),
		enabled: !!prov,
	});

	const { data: proveedores, isLoading: isLoadingProveedores } = useQuery({
		queryKey: ['proveedores'],
		queryFn: async () => await getProveedoresService(),
	});

	const { data: datosBigData, isLoading: isLoadingDatosBigData } = useQuery({
		queryKey: ['datosAsin', asin],
		queryFn: async () => await getBigDataService(asin),
		enabled: !!asin,
	});

	const fechaData = useMemo(()=>fecha? toJavaDate(fecha):null, [fecha]);

	const { data: novedadesProveedor, isLoading: isLoadingNovedadesProveedor } = useQuery({
		queryKey: ['novedadesProveedor', prov, fechaData],
		queryFn: async () => await getNovedadesProveedorService(prov, fechaData),
		enabled: !!prov && !!fecha,
	});

	return {
		productosAmazon,
		productosProv,
		isLoadingProductosAmazon,
		isLoadingProductosProv,
		proveedores,
		isLoadingProveedores,
		datosBigData,
		isLoadingDatosBigData,
		novedadesProveedor,
		isLoadingNovedadesProveedor
	};
};

export default useZascaTacticalHook;
