import axios from 'axios';
import { toast } from 'react-toastify';
import { WS_ZBIGDATAURI, WS_ZSYNCURI } from './commonHelper';

export const getProductosAmazonService = () =>
	axios
		.get(`${WS_ZBIGDATAURI}getAllProductoAmazonData`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProductosProveedorService = (prov) =>
	axios
		.get(`${WS_ZBIGDATAURI}getProductosProveedorActualizadosByProveedor?prov=${prov}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProveedoresService = () =>
	axios
		.get(`${WS_ZBIGDATAURI}getProveedores`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo proveedores ' + err, 10000);
		});

export const getBigDataService = (asin) =>
	axios
		.get(`${WS_ZBIGDATAURI}getBigDataAmazonByAsin?asin=${asin}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo proveedores ' + err, 10000);
		});

export const getProductoAmazonEanService = (ean) =>
	axios
		.get(`${WS_ZSYNCURI}productoAmazonEan?ean=${ean}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProductosProveedorEanService = (ean) =>
	axios
		.get(`${WS_ZSYNCURI}productosProveedorEan?ean=${ean}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});

export const getProductoZascaloEanService = (ean) =>
	axios
		.get(`${WS_ZSYNCURI}productoZascalo?ean=${ean}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});
export const getNovedadesProveedorService = (prov, fecha)=>{
	return axios
		.get(`${WS_ZBIGDATAURI}getProductosNuevosByFecha?proveedor=${prov}&fecha=${fecha}`)
		.then((res) => res?.data)
		.catch((err) => {
			toast.error('Error obteniendo productos ' + err, 10000);
		});
}