import PropTypes from 'prop-types';
import useZascaTacticalHook from '../../hooks/useZascaTacticalHook';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useCallback } from 'react';
import GraficoLineas from './GraficoLineas';

const DatosHistorico = ({ asin }) => {
    const { datosBigData } =useZascaTacticalHook({ asin });

    const renderFecha = useCallback((row, col) => {
            const value = row[col.field];
            const fecha = value? new Date(value): null;
            return <span>{fecha?.toLocaleDateString()}</span>;
        }, []);

    return (
			<div className='grid'>
				<div className='col-6'>
					<GraficoLineas
						value={datosBigData?.map((d) => ({ ...d, fecha: new Date(d.fecha) }))}
						attribute1='precioBestAmazon'
						attribute2='precioBestFBA'
						attribute3='precioBestFPM'
					/>
				</div>
				<div className='col-6'>
					<GraficoLineas
						value={datosBigData?.map((d) => ({ ...d, fecha: new Date(d.fecha), categoriaRanking2: -d.categoriaRanking2??0, categoriaRanking3: -d.categoriaRanking3??0 }))}
						attribute1='categoriaRanking2'
						attribute2='categoriaRanking3'
					/>
				</div>
				<div className='col-12'>
					<DataTable
						value={datosBigData}
						className='text-xs'
						resizableColumns
						columnResizeMode='expand'
						stripedRows
						size='small'
						scrollable
						scrollHeight='800px'
					>
						<Column field='precioBuyBox' header='Buybox' />
						<Column field='precioBestFBA' header='Precio FBA' />
						<Column field='precioBestFPM' header='Precio FBM' />
						<Column field='precioBestAmazon' header='Precio Amazon' />
						<Column field='numSellersFBA' header='Sellers FBA' />
						<Column field='numSellersFBM' header='Sellers FBM' />
						<Column field='amazonSell' header='Amazon Sells' />
						<Column field='categoriaRanking1' header='Ranking 1' />
						<Column field='categoriaRanking2' header='Ranking 2' />
						<Column field='categoriaRanking3' header='Ranking 3' />
						<Column field='feesVenta' header='Fees venta' />
						<Column field='feesFba' header='Fees FBA' />
						<Column field='shippingTime' header='Shipping time' />
						<Column field='shippingAvailability' header='Shipping av' />
						<Column field='shippingAvailableDate' header='Shipping av date' />
						<Column field='shippingFrom' header='Shipping from' />
						<Column field='fecha' header='Fecha' body={renderFecha} />
						<Column field='fechaAsin' header='Fecha asin' body={renderFecha} />
						<Column field='fechaPrices' header='Fecha Prices' body={renderFecha} />
						<Column field='fechaFees' header='Fecha fees' body={renderFecha} />
					</DataTable>
				</div>
			</div>
		);
};

export default DatosHistorico;

DatosHistorico.propTypes = {
    asin: PropTypes.string.isRequired
};
